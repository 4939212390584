<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Date From</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.fromDate.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Date To</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.toDate.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationID.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" :value="id" :key="id">
                        {{ location }}
                    </option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Shift</label>
                <select class="form-control" v-model="state.optionFields.shiftID.val">
                    <option value="0">All</option>
                    <option v-for="[id, label] in cache.shiftCache.SHIFTS" :value="id" :key="id">
                        {{ label }}
                    </option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Employee</label>
                <Typeahead cdi_type="employee" v-bind:ID.sync="state.optionFields.employee.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Hours Type</label>
                <select class="form-control" v-model="state.optionFields.type.val">
                    <option value="-1">All</option>
                    <option v-for="[id, label] in hourTypes" :value="id" :key="id">
                        {{ label }}
                    </option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6'>
                <label>Minimum Hours</label><input type="text" class="form-control" v-model="state.optionFields.hours.val">
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type="checkbox" :true-value="1" :false-value=null v-model="state.optionFields.isProduction.val"> Production</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type="checkbox" :true-value="1" :false-value=0 v-model="state.optionFields.includeDisabled.val"> Include Termed</label>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import shiftCache from '@/cache/shift.cache.js';
    import hoursCache from '@/cache/hours.cache';
    import Typeahead from "@/components/utils/typeaheads/Typeahead";
    import DatePicker from "@/components/utils/DatePicker";

    export default {
        name: "Options",
        components: {
            DatePicker,
            Typeahead
        },
        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    cdiLocationCache,
                    shiftCache,
                    hoursCache
                }
            }
        },
        computed: {
            hourTypes: function () {
                let data = this.cache.hoursCache.TYPES;

                return data.slice(0, 5);
            }
        }
    }
</script>

<style scoped>

</style>