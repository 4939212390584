export default {
	TYPES: [
		[0, 'Regular'],
		[1, 'Sick'],
		[2, 'PTO'],
		[3, 'Holiday'],
		[4, 'Funeral'],
		[5, 'OT'],
		[6, 'Jury Duty'],
		[7, 'COVID-19 Dependent'],
		[8, 'COVID-19 Self']
	]
}