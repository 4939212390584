<template>
    <div>
        <h1>Hours</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <Fields />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from "@/store/Report.store";
    import Options from "@/components/reports/hours_opt";
    import Results from "@/components/reports/utils/Results";
    import DateHelpers from "@/store/DateHelpers";
    import GroupBy from "@/components/reports/utils/GroupBy";
    import Fields from "@/components/reports/utils/Fields";

    export default {
        name: "hours",
        components: {
            GroupBy,
            Fields,
            Results,
            Options,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    fromDate: {val: DateHelpers.getSundayXWeeksAgo(0), qsField: '[dates][clock_date_from]'},
                    toDate: {val: DateHelpers.getLastOfTheMonth(), qsField: '[dates][clock_date_to]'},
                    cdiLocationID: {val: 0, qsField: '[numbers][uss.cdi_location_id]'},
                    shiftID: {val: 0, qsField: '[numbers][us.user_staff_shift_id]'},
                    employee: {val: '', qsField: '[numbers][u.user_id]'},
                    type: {val: -1, qsField: '[znumbers][type]'},
                    hours: {val: 0, qsField: '[gtenumbers][hours]'},
                    isProduction: {val: null, qsField: '[production]'},
                    includeDisabled: {val: 0, qsField:'[include_disabled]'}
                },
                groupByFields: {
                    employee_count: { label: 'Employee' },
                },
                dataFields: {
                    user_id: { label: 'ID', checked: true },
                    employee_name: { label: 'Name', checked: true },
                    hours: { label: 'Total', checked: true },
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Hours']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>

<style scoped>

</style>